import { Fragment, useEffect, useState } from "react";
import "./user_dashboard.scss";

/////////Desktop////////////////////

import LeftSideBarComponent from "../structure_components/desktop_screens/left_sideBar_component/left_sideBar_component";
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserDashboardContentComponent from "./user_dashboard_content_component/user_dashboard_content_component";
import LoaderPopUp from "../../../components/loader/loader";
import CustomModal from "../../../components/Modal/CustomModal";

import { Analytics } from "aws-amplify";
//Redux
import { connect } from "react-redux";
import { isDesktop } from "react-device-detect";
import { useLocation } from "react-router-dom";
import images from "../../../utilities/images/images";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import { url_174, url_175 } from "../../../custom_utilities/api_services";
import axios from "axios";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";
import uiActions from "../../../redux/ui/action";
import { setBlockedStatus } from "../../../redux/blocking_user/BlockUserSlice";
import { setMultipleLoginStatus } from "../../../redux/multiple_login_detect/MultipleLoginDetectSlice";
import initSingular from "../../../singular";
import userActivityActions from "../../../redux/user_activity/action";

const UserDashboard = (props) => {
  let {
    loader,
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setBlockedStatusAction,
    setMultipleLoginStatusAction,
    setCategoryData,
    setIsCategorizedSub
  } = props;

  useEffect(() => {
    initSingular();
  }, []);

  const location = useLocation();
  const [state, setState] = useState({
    coins: 0,
    coinPopup: false,
    coinDetailsPopup: false,
    coinUsesPoints: [],
  });
  const addCoinsOnSignup = async () => {
    const data = { type: "NewUser_Registration" };
    try {
      const response = await axios.post(url_174, data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          console.log(response.data);
          setState((prev) => {
            return {
              ...prev,
              coins: Math.floor(response.data.coins),
              coinPopup: true,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!isEmpty(location.state)) {
      if (location.state.reg_mcoin == 0) {
        addCoinsOnSignup();
        coinUsesApi();
      }
    }
  }, []);
  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        console.log(response.data.Uses);
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: response.data.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        coinPopup: false,
        coinDetailsPopup: false,
      };
    });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);
  return (
    <Fragment>
      <CustomModal
        show={state.coinPopup}
        onHide={handleClose}
        clsClass="coinClsClass"
        className="coinModal"
      >
        <div className="text-center coinWelcome">
          <img src={images.coin_welcom} />
        </div>
        <div className="d-flex justify-content-center align-items-end gap-3">
          <img src={images.coins} />
          <p className="mb-0 coinNum">{state.coins}</p>
          <p className="mb-0 coinText">Motion Coins </p>
        </div>
        <p className="text-center my-3 coinText1">
          Yay! You have earned {state.coins} MC by registering.
        </p>
        <div className="text-center">
          <button
            className="coinBtn"
            onClick={() => {
              setState((prevState) => {
                return {
                  ...prevState,
                  coinPopup: false,
                  coinDetailsPopup: true,
                };
              });
            }}
          >
            Click to know how to earn more
          </button>
        </div>
      </CustomModal>
      <CustomModal
        show={state.coinDetailsPopup}
        onHide={handleClose}
        className="coinDetailsPopup"
      >
        <p className="coinHeading">How to earn Motion Coins?</p>
        <p>
          You can earn Motion Coins by performing various activities on Motion
          App.
        </p>
        <ul>
          {state.coinUsesPoints.map((point) => {
            return <li key={point.id}>{point.description}</li>;
          })}
          {/* <li>Watching Subject Videos</li>
      <li>Playing Motion Daily and Weekly Quizzes</li>
      <li>Finishing Practice Sheets</li>
      <li>Attending Motion Online Tests</li>
      <li>Posting Doubts in Doubts Section</li>
      <li>Purchasing Motion Products from Store</li>
      <li>Referring your friends </li>
      <li>Topping the weekly leaderboard</li> */}
        </ul>
        <p className="text-center mb-0">Earn While You Learn!</p>
      </CustomModal>
      <div className="user_dashboard_wrapper">
        <div className="user_dashboard_inner_wrapper">
          {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <UserDashboardContentComponent
                history={history}
                startLoading={startLoading}
                finishLoading={finishLoading}
                setBlockedStatusAction={setBlockedStatusAction}
                setMultipleLoginStatusAction={setMultipleLoginStatusAction}
                setCategoryData={setCategoryData}
                setIsCategorizedSub={setIsCategorizedSub}
              />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_dashboard_left_wrapper">
                {/* <LeftSideBarComponent /> */}
              </div>
              <div className="user_dashboard_right_wrapper">
                <HeaderComponent history={history} />
                <UserDashboardContentComponent
                  history={history}
                  startLoading={startLoading}
                  finishLoading={finishLoading}
                  setBlockedStatusAction={setBlockedStatusAction}
                  setMultipleLoginStatusAction={setMultipleLoginStatusAction}
                  setCategoryData={setCategoryData}
                  setIsCategorizedSub={setIsCategorizedSub}
                />
              </div>
            </Fragment>
          )}
        </div>
        <Footer_component />
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  // console.log('isMultipleLogin',)

  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    // isMultipleLogin: state.MultipleLoginDetectSlice.isMultipleLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setBlockedStatusAction: (payload) => {
      dispatch(setBlockedStatus(payload));
    },

    setMultipleLoginStatusAction: (payload) => {
      dispatch(setMultipleLoginStatus(payload));
    },
    setCategoryData: (payload) => {
      dispatch(userActivityActions.setCategoryData(payload));
    },
    setIsCategorizedSub: (payload) => {
      dispatch(userActivityActions.setIsCategorizedSub(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
