import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "./VideoJsPlayer.styles.css";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import "videojs-contrib-eme";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const VideoJsPlayer = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady, source, dType } = props;
  const { keySystem } = props;
  const [isLive, setIsLive] = useState(false);

  const customHeaders = {
    'pallycon-customdata-v2': source.link.token,
  };

  const base64DecodeUint8Array = (input) => {
    try {
      const raw = window.atob(input);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }

      return array;
    } catch (error) {
      console.error("Invalid Base64 string", error);
      return new Uint8Array();
    }
  };

  // Add global header configuration
  useEffect(() => {
    if (videojs.Vhs) {
      videojs.Vhs.xhr.beforeRequest = (options) => {
        if (!options.headers) {
          options.headers = {};
        }
        options.headers["pallycon-customdata-v2"] = customHeaders;
        return options;
      };
    }
  }, [customHeaders]);

  // Rest of your existing code...
  const base64EncodeUint8Array = (input) => {
    const keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN;
      chr3 = i < input.length ? input[i++] : Number.NaN;

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output +=
        keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4);
    }
    return output;
  };

  const arrayToString = (array) => {
    const uint16array = new Uint16Array(array.buffer);
    return String.fromCharCode.apply(null, uint16array);
  };

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      const fairplayCertUri =
        "https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=5VT4";
      const licenseUri = "https://license.videocrypt.com/validateLicense";

      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          plugins: {
            eme: {
              keySystems:
                keySystem === "com.apple.fps.1_0"
                  ? {
                      "com.apple.fps.1_0": {
                        getCertificate: (emeOptions, callback) => {
                          videojs.xhr(
                            {
                              url: fairplayCertUri,
                              method: "GET",
                              headers: customHeaders,
                            },
                            (err, response, responseBody) => {
                              if (err) {
                                callback(err);
                                return;
                              }
                              callback(null, base64DecodeUint8Array(responseBody));
                            }
                          );
                        },
                        getContentId: (emeOptions, initData) => {
                          const contentId = arrayToString(initData);
                          return contentId.substring(
                            contentId.indexOf("skd://") + 6
                          );
                        },
                        getLicense: (emeOptions, contentId, keyMessage, callback) => {
                          videojs.xhr(
                            {
                              url: licenseUri,
                              method: "POST",
                              responseType: "text",
                              body: "spc=" + base64EncodeUint8Array(keyMessage),
                              headers: {
                                "Content-type":
                                  "application/x-www-form-urlencoded",
                                "pallycon-customdata-v2": source.link.token,
                                ...customHeaders,
                              },
                            },
                            (err, response, responseBody) => {
                              if (err) {
                                callback(err);
                                return;
                              }
                              callback(null, base64DecodeUint8Array(responseBody));
                            }
                          );
                        },
                      },
                    }
                  : {
                      [keySystem]: {
                        licenseUrl:
                          "https://license.videocrypt.com/validateLicense?pallyconCustomdataV2=" +
                          source?.link?.token,
                      },
                    },
            },
          },
        },
        () => {
          onReady && onReady(player);
          player.controlBar.progressControl.seekBar.on("mousedown", (event) => {
            event.preventDefault(); // Prevent the default behavior of seeking
            console.log("event", event);
          });
        }
      ));

      // Rest of your player configuration...
      player.qualityLevels();
      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      class RewindButton extends videojs.getComponent("Button") {
        constructor(player, options) {
          super(player, options);
          this.addClass("rewindIcon");
          this.controlText("Rewind 10 seconds");
        }

        handleClick() {
          this.player().currentTime(this.player().currentTime() - 10);
        }
      }

      class FastForwardButton extends videojs.getComponent("Button") {
        constructor(player, options) {
          super(player, options);
          this.addClass("fast-forward-icon");
          this.controlText("Fast Forward 10 seconds");
        }

        handleClick() {
          this.player().currentTime(this.player().currentTime() + 10);
        }
      }

      videojs.registerComponent("rewind", RewindButton);
      videojs.registerComponent("fastForward", FastForwardButton);

      player.getChild("ControlBar").addChild("rewind", {}, 2);
      player.getChild("ControlBar").addChild("fastForward", {}, 3);

      // Keyboard controls
      player.on("keydown", (e) => {
        const playerVolume = player.volume();
        const playerCurrentTime = player.currentTime();
        switch (e.code) {
          case "Space":
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
            break;
          case "ArrowRight":
            player.currentTime(playerCurrentTime + 10);
            break;
          case "ArrowLeft":
            player.currentTime(playerCurrentTime - 10);
            break;
          case "ArrowUp":
            player.volume(playerVolume + 0.1);
            break;
          case "ArrowDown":
            player.volume(playerVolume - 0.1);
            break;
          case "KeyM":
            player.volume(0);
            break;
          default:
            return;
        }
      });

      if (dType === 1 || dType === 3) {
        if (props?.source?.link?.token) {
          player.src({
            src: props?.source?.link?.file_url,
            type: "application/dash+xml",
            keySystems: {
              [keySystem]:
                "https://license.videocrypt.com/validateLicense?pallyconCustomdataV2=" +
                props?.source?.link?.token,
              withCredentials: true,
              headers: customHeaders,
            },
          });
        }
      }

      videoElement.addEventListener("loadedmetadata", handleMetadata);

      function handleMetadata() {
        setIsLive(player.liveTracker.isLive());
        const videoDuration = videoElement.duration;
        console.log("Video Duration:", videoDuration);
      }

      return () => {
        videoElement.removeEventListener("loadedmetadata", handleMetadata);
      };
    }
  }, [options, onReady, keySystem, props.source.link]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <div className={`player ${isLive ? "isLiveVideo" : "noLiveVideo"}`}>
        <div data-vjs-player>
          <video
            ref={videoRef}
            className="video-js vjs-matrix vjs-big-play-centered"
          >
            <source
              src={props?.source?.link?.file_url}
              type={"application/x-mpegURL"}
            />
          </video>
        </div>
      </div>
    </>
  );
};

export default VideoJsPlayer;