import { useHistory } from "react-router-dom";
import images from "../../../../../utilities/images/images";
import "./CommonBannerComponent.scss";
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import axios from "axios";
import { url_213 } from "../../../../../custom_utilities/api_services";
import { useEffect, useState } from "react";
import userActivityActions from "../../../../../redux/user_activity/action";

const CommonBannerComponent = (props) => {
  let { tab, startLoading, finishLoading, setCommonBannerData } = props;

  useEffect(() => {
    getAttemptDetails();
  }, []);

  const [state, setState] = useState({
    packageActive: 1,
    attemptsLeft: 0,
  });

  const getPaperType = () => {
    const tabToPaperType = {
      adaptive_cps: 4,
      cps: 2,
      "Exam Cracker - JEE Main": 9,
      "Exam Cracker - JEE Advanced": 8,
      "Exam Cracker - NEET": 6,
    };
    return tabToPaperType[tab];
  };

  const getAttemptDetails = async () => {
    startLoading();
    try {
      const response = await axios.get(
        `${url_213}/?paper_type=${getPaperType()}`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState({
          ...state,
          packageActive: response.data.data.package_active,
          attemptsLeft: response.data.data.is_attempt,
        });
        setCommonBannerData({
          ...state,
          packageActive: response.data.data.package_active,
          attemptsLeft: response.data.data.is_attempt,
        });
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const history = useHistory();

  const width = tab.includes("Exam Cracker") ? "68%" : "100%";

  const handleButtonClick = () => {
    history.push({ pathname: "/store", state: { tab: "online_learning" } });
    // if (tab === "adaptive_cps") {
    // } else if (tab === "cps") {
    // } else if (tab.includes("Exam Cracker")) {
    // }
  };

  return (
    <>
      {tab.includes("Exam Cracker") && !state.packageActive && (
        <div
          className="common_promo_banner d-flex align-items-center"
          style={{ width: width, margin: "auto" }}
        >
          <div className="d-flex align-items-center">
            <img src={images.commonBannerCircle} alt="circle" />
            <img
              className="pos-img"
              src={images.commonBannerLogo}
              alt="paper rocket"
            />
          </div>
          <div>
            <p>Exam Mastery</p>
            <p>
              Your path to exam success begins with <span>‘Exam Cracker’.</span>
            </p>
          </div>
          <div>
            <button onClick={handleButtonClick}>Buy Now</button>
          </div>
        </div>
      )}
      {(tab === "cps" || tab === "adaptive_cps") && !state.packageActive && (
        <div className="common_promo_banner d-flex align-items-center">
          <div className="d-flex align-items-center">
            <img src={images.commonBannerLogoCps} alt="paper rocket" />
          </div>
          <div>
            {state.attemptsLeft > 0 ? (
              <p>Only {state.attemptsLeft} attempts left</p>
            ) : (
              <p>No free attempts left</p>
            )}
            {tab === "cps" ? (
              <p>Get the CPS to improve more on weak topics</p>
            ) : (
              <p>Get your customised CPS to improve more on weak topics</p>
            )}
          </div>
          <div>
            <button onClick={handleButtonClick}>Get Now</button>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setCommonBannerData: (payload) => {
      dispatch(userActivityActions.setCommonBannerData(payload));
    },
  };
};

export default connect(0, mapDispatchToProps)(CommonBannerComponent);
