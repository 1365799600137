import { Fragment, useState, useEffect } from "react";
import "./subject_content_component.scss";
import images from "../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { url_9, url_10 } from "../../../../../custom_utilities/api_services";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";

const SubjectContentComponent = (props) => {
  let {
    history,
    userPreference,
    subjectData,
    setSubjectData,
    setTopicData,
    startLoading,
    finishLoading,
    categoryData,
    iscategorizedsubdata,
  } = props;

  let { courseDetails, classDetails } = userPreference;
  const location = useLocation();
  let freeCourseId = location.courseId;

  const [state, setState] = useState({
    subjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    subjectList: {},
    topicList: {},
    initialSubjectCheck: true,
    selectedCategory: "", 
  });

  useEffect(() => {
    getSubjectData();
    if (iscategorizedsubdata?.is_categorized_sub !== 1) {
      getSubjectList();
    }
  }, []);

  useEffect(() => {
    if (
      iscategorizedsubdata?.is_categorized_sub === 1 &&
      subjectData?.subjects
    ) {
      setState((prevState) => ({
        ...prevState,
        subjectList: { subject: subjectData.subjects },
        subjectDataDetails: {
          subjectId: subjectData.subjectId,
          subjectName: subjectData.subjectName,
          subjectIcon: subjectData.subjectIcon,
        },
      }));
    } else {
      getSubjectData();
      getSubjectList();
    }
  }, [subjectData, iscategorizedsubdata]);

  useEffect(() => {
    if (
      !isEmpty(state.subjectList) &&
      state.subjectList.subject?.length &&
      state.initialSubjectCheck
    ) {
      getInitialData();
    }
  }, [state.subjectList]);

  const getInitialData = () => {
    let { subjectId } = subjectData;
    let { subjectList } = state;
    setState((prevState) => ({ ...prevState, initialSubjectCheck: false }));

    if (subjectId) {
      getSubjectData();
      getTopics(courseDetails[0].course_id, subjectId);
    } else if (subjectList.subject && subjectList.subject.length > 0) {
      const firstSubject = subjectList.subject[0];
      setSubjectData({
        subjectId: firstSubject.sub_id,
        subjectName: firstSubject.sub_name || firstSubject.name,
        subjectIcon: firstSubject.icon,
      });
      setState((prevState) => ({
        ...prevState,
        subjectDataDetails: {
          subjectId: firstSubject.sub_id,
          subjectName: firstSubject.sub_name || firstSubject.name,
          subjectIcon: firstSubject.icon,
        },
      }));
      getTopics(courseDetails[0].course_id, firstSubject.sub_id);
    }
  };

  const getSubjectData = () => {
    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        subjectId: subjectData.subjectId,
        subjectName: subjectData.subjectName,
        subjectIcon: subjectData.subjectIcon,
      },
    }));
  };

  const getSubjectList = async () => {
    let data = {
      course_id: location.courseId ? freeCourseId : courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTopics = async (course_id, sub_id) => {
    let data = {
      course_id: location.courseId ? freeCourseId : course_id,
      subject_id: sub_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_10, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    setSubjectData({
      ...subjectData, // Keep the category and subjects data
      subjectId: sub_id,
      subjectName: sub_name,
      subjectIcon: icon,
    });
    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getTopics(courseDetails[0].course_id, sub_id);
  };

  const handleTopicChange = (
    topic_id,
    topic_name,
    icon,
    is_video_available,
    is_live_class_available,
    is_excersice_available,
    is_notes_available,
    is_dpp_available,
    is_additional_video_available
  ) => {
    setTopicData({
      topicId: topic_id,
      topicName: topic_name,
      topicIcon: icon,
      topicVideo: is_video_available,
      is_live_class_available,
      is_excersice_available,
      is_notes_available,
      is_dpp_available,
      is_additional_video_available,
    });
    history.push({
      pathname: "/topic_component",
      state: location.courseId,
    });
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
  
    setState((prevState) => ({
      ...prevState,
      selectedCategory: selectedCategoryId,
      subjectList: {
        subject: filterSubjectsByCategory(selectedCategoryId),
      },
    }));
  };

  useEffect(() => {
    if (iscategorizedsubdata?.is_categorized_sub === 1 && categoryData?.length) {
      if (location.state?.categoryId) {
        setState((prevState) => ({
          ...prevState,
          subjectList: {
            subject: filterSubjectsByCategory(location.state.categoryId),
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          subjectList: { subject: categoryData.flatMap(cat => cat.subject) },
        }));
      }
    } else {
      getSubjectList(); // For non-categorized subjects
    }
  }, [categoryData, location.state?.categoryId, iscategorizedsubdata]);
  
  const filterSubjectsByCategory = (categoryId) => {
    const selectedCategory = categoryData.find((category) => category.id === parseInt(categoryId));
    return selectedCategory ? selectedCategory.subject : [];
  };
  


  useEffect(() => {
    if (location.state?.categoryId) {
      setState((prevState) => ({
        ...prevState,
        selectedCategory: location.state.categoryId,
      }));
    }
  }, [location.state?.categoryId]);
  


  const renderSubjectList = () => {
    let subjects = [];
  
    if (iscategorizedsubdata?.is_categorized_sub === 1) {
      if (categoryData && categoryData.length > 0) {
        if (state.selectedCategory) {
          const selectedCategory = categoryData.find(
            (category) => category.id === parseInt(state.selectedCategory)
          );
          subjects = selectedCategory ? selectedCategory.subject : [];
        } else {
          subjects = categoryData
            .map((category) => category.subject)
            .flat();
        }
      }
    } else {
      subjects = state.subjectList.subject || [];
    }
  
    return subjects.map((element, index) => {
      const subId = element.sub_id;
      const subName = element.sub_name;
      const subIcon = element.icon;
      const colorCode = element.color_code?.split(",")[0] || "";
      const textColor = element.text_color || "";
  
      return (
        <div
          key={index}
          className="single_select_subject"
          style={{
            backgroundColor: colorCode, // Set background color
          }}
        >
          <div className="subject_icon">
            <img src={subIcon} alt="icon" />
          </div>
          <input
            type="radio"
            id={`subject_${subId}`}
            name="subject"
            value={subId}
            checked={subId === parseInt(state.subjectDataDetails.subjectId)}
            onChange={(event) =>
              handleChangeSubject(event, subId, subName, subIcon)
            }
          />
          <label htmlFor={`subject_${subId}`} style={{ color: textColor }}>
            {subName}
          </label>
        </div>
      );
    });
  };
  useEffect(() => {
    if (iscategorizedsubdata?.is_categorized_sub === 1 && state?.subjectList?.subject) {
      const firstSubject = state?.subjectList?.subject[0];
      const currentSubjectId = parseInt(state?.subjectDataDetails?.subjectId);
  
      const isCurrentSubjectValid = state?.subjectList?.subject?.some(
        subject => subject?.sub_id === currentSubjectId
      );
  
      if (!isCurrentSubjectValid || !currentSubjectId) {
        setSubjectData({
          subjectId: firstSubject?.sub_id,
          subjectName: firstSubject?.sub_name || firstSubject?.name,
          subjectIcon: firstSubject?.icon,
        });
  
        setState((prevState) => ({
          ...prevState,
          subjectDataDetails: {
            subjectId: firstSubject?.sub_id,
            subjectName: firstSubject?.sub_name || firstSubject?.name,
            subjectIcon: firstSubject?.icon,
          },
        }));
  
        // Fetch topics for the first subject
        getTopics(
          location?.courseId ? freeCourseId : courseDetails?.[0]?.course_id, 
          firstSubject?.sub_id
        );
      }
    }
  }, [state?.subjectList, state?.selectedCategory]);
  return (
    <Fragment>
    <div className="subjectContentComponent_wrapper">
      <div className="container">
        <div className="subjectContentComponent_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              {iscategorizedsubdata?.is_categorized_sub === 1 && (
                <div className="category_dropdown_wrapper" style={{ float: "right", padding: "8px" }}>
                  <select
                    value={state.selectedCategory || location.state?.categoryId || ""}
                    onChange={handleCategoryChange}
                    className="category_dropdown"
                    style={{ padding: "8px" }}
                  >
                    <option value="">Select Category</option>
                    {categoryData?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="subject_collection_wrapper">
                    {renderSubjectList()}
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="topic_collection_wrapper">
                    {!isEmpty(state.topicList) ? (
                      state.topicList.topic?.length ? (
                        state.topicList.topic.map((element, index) => (
                          <div
                            key={index}
                            className="single_topic_wrapper"
                            onClick={() => handleTopicChange(element.topic_id,
                              element.topic_name,
                              element.icon,
                              element.is_video_available,
                              element.is_live_class_available,
                              element.is_excersice_available,
                              element.is_notes_available,
                              element.is_dpp_available,
                              element.is_additional_video_available)}
                          >
                            <div className="topic_description_wrapper">
                              <div className="topic_details_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">{element.topic_name}</p>
                                </div>
                              </div>
                              <div className="caret_wraper">
                                <img src={images.right_Ar} alt="arrow" />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no_data_found_wrapper">
                          <img src={images.no_data} alt="No data" />
                        </div>
                      )
                    ) : (
                      <div className="no_data_found_wrapper">
                        <img src={images.no_data} alt="No data" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
   
  );
};

const mapStateToProps = (state) => ({
  subjectData: state.userActivity.subjectData,
  categoryData: state.userActivity.categoryData,
  iscategorizedsubdata: state.userActivity.is_categorized_sub,
  userPreference: state.userPreference,
});

const mapDipatchToProps = (dispatch) => ({
  startLoading: () => {
    dispatch(uiActions.startLoading());
  },
  finishLoading: () => {
    dispatch(uiActions.finishLoading());
  },
  setTopicData: (payload) => {
    dispatch(userActivityActions.setTopicData(payload));
  },
  setSubjectData: (payload) => {
    dispatch(userActivityActions.setSubjectData(payload)); 
  },
  setCategoryData: (payload) => {
    dispatch(userActivityActions.setCategoryData(payload)); 
  },
});

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(SubjectContentComponent);
